import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  AvatarContainer,
  Divider,
  Heading,
  ModalContent,
  ProfileContainer,
  RadioButton,
  TemplateContainer,
  // TemplateImage,
  TemplateOption,
  Button,
  NoteText,
  CharacterCount,
  Label,
  RadioButtonWrapper,
  StyledModal,
  LeftContent,
} from "./styles";
import LazyImage from "../common/LazyImage/LazyImage";
import {
  ManagerArrowIcon,
  ManagerCloseIcon,
  ManagerCrossIcon,
  ManagerDisabledArrowIcon,
  ManagerDisabledPostIcon,
  // ManagerDropDownIcon,
  ManagerEmojiIcon,
  ManagerLeftArrowIcon,
  ManagerPostIcon,
} from "../../utils/icons";
import { FieldTitle, TitleContainer } from "../PeerRecognition/styles";
import { toast } from "react-toastify";
import {
  GetCompanyExemplifiesValues,
  GetTeamMembersData,
  GetTemplatesWithUserData,
  AddInspirationPost,
} from "../../redux/constants/apiConstants";
import { fetchApi } from "../../utils/methods";
import { ImageUrl } from "../../utils/constants";
import Image from "../Image";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import SocialFeedsEmojiPicker from "../SocialFeedsEmojiPicker";
import {
  UserContainer,
  UserDropdownContainer,
  ProfilePicture,
  DropDownStyledImage,
  ListDropdown /*ShowTaggedUser, Cross*/,
} from "../SocialFeedsV2/styles";
import styled from "styled-components";
import { Select, MenuItem, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";

const StyledInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 16px;
  margin-top: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  cursor: ${({ cursor }) => (cursor ? "not-allowed" : "")};
`;

const ShowTaggedUser = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding: 4px;
  gap: 4px;
  background-color: #fefefe;
  border: 1px solid #efefef;
  border-radius: 40px;
  width: auto;
  height: 32px;
  > div {
    color: #184a61;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

const Cross = styled.div`
  cursor: pointer;
  margin-left: 8px;
  width: 16px;
  height: 16px;
`;

const RecognitionModal = ({
  show,
  onClose,
  modalData,
  recognitionType,
  loggedInUser,
}) => {
  const [page, setPage] = useState(1);
  const [recipient, setRecipient] = useState("");
  const [exemplifiesData, setExemplifiesData] = useState([]);
  const [charColor, setCharColor] = useState("#BDBDBD");
  const [templateData, setTemplateData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [displayEmoji, setDisplayEmoji] = useState();
  false;
  const [emojiData, setEmojiData] = useState(null);
  const [emojiId, setEmojiId] = useState(null);
  const [emojiNative, setEmojiNative] = useState(null);
  const [emojiSkin, setEmojiSkin] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [focused, setFocused] = useState(false);
  const [searchUser, searchUserName] = useState("");
  const [getSearchUser, getSearchUserData] = useState([]);
  const [getUserId, getUserIdData] = useState([]);
  const [exemplifyId, getexemplifyid] = useState([null]);
  const [exemplifyName, getexemplifyName] = useState(null);
  const [description, setDescription] = useState("");
  const [position, setPosition] = useState({ start: 0, end: 0 });
  const maxChars = 3000;
  const emojiref = useRef(null);
  const myref = useRef(null);
  const textareaRef = useRef(null);

  window.console.log("exemplifiesData", exemplifyName);
  window.console.log("id are", emojiData, emojiId, emojiNative, emojiSkin);

  const handleTemplateSelect = (index) => {
    setSelectedTemplate(index);
  };

  const handleEmojiClick = (event) => {
    if (
      !isNull(emojiref) &&
      !isUndefined(emojiref) &&
      !(emojiref && emojiref.current && emojiref.current.contains(event.target))
    ) {
      setDisplayEmoji(false);
    }
  };

  const handleClick = (event) => {
    if (
      !isNull(myref) &&
      !isUndefined(myref) &&
      !isNull(myref.current) &&
      !isUndefined(myref.current) &&
      !(myref && myref.current && myref.current.contains(event.target))
    ) {
      // this.setState({ showConnectDevice: false });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("mousedown", handleEmojiClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("mousedown", handleEmojiClick);
    };
  }, []);

  const handleSelect = (id, coreValue) => {
    setSelectedValue(coreValue);
    getexemplifyid(id);
    getexemplifyName(coreValue);
  };

  const handleChange = (event) => {
    const index = event.target.value;
    const selectedData = exemplifiesData?.[index];
    if (selectedData) {
      handleSelect(selectedData.id, selectedData.core_value);
    }
  };

  useEffect(() => {
    if (description.length >= 3000) {
      setCharColor("#F3384A");
    } else {
      setCharColor("#BDBDBD");
    }
  }, [description]);

  useEffect(() => {
    fetchTemplateData();
    getTeamMemberDetails();
    getCompanyExemplifiesValues();
  }, []);

  const fetchTemplateData = async () => {
    const apiUrl = `${GetTemplatesWithUserData}/${recognitionType}/${modalData?.member_id}`;

    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setTemplateData(res.data.templates);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const getCompanyExemplifiesValues = async () => {
    const apiUrl = `${GetCompanyExemplifiesValues}`;

    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setExemplifiesData(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const getTeamMemberDetails = async (search) => {
    const company_id = 1;
    const limit = 6;
    const name = search ? search : searchUser;
    const postData = {
      company_id: company_id,
      name: name,
      limit: limit,
    };
    const apiUrl = `${GetTeamMembersData}`;

    try {
      const res = await fetchApi(apiUrl, "POST", postData);
      if (res.status === "success") {
        setRecipient(res?.data?.response);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const showEmojiPicker = () => {
    setDisplayEmoji((prev) => !prev);
  };

  const hideEmojiPicker = () => {
    setDisplayEmoji(false);
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "inherit";
      textarea.style.height = `${textarea.scrollHeight}px`;
      if (textarea.scrollHeight > 500) {
        textarea.style.height = "500px";
      }
    }
  };

  const addDescription = (e) => {
    const text = e.target.value;
    // Allow user to remove characters if they exceed the limit
    if (text.length <= 3000) {
      let position = getPosition(e.target);
      setPosition(position);
      setDescription(text);
      adjustHeight();
    } else {
      // Prevent adding more characters beyond the limit
      let position = getPosition(e.target);
      setPosition(position);
      setDescription(text.slice(0, 3500));
      adjustHeight();
    }
  };

  const getPosition = (el) => {
    let start = 0,
      end = 0,
      normalizedValue,
      range,
      textInputRange,
      len,
      endRange;

    if (
      typeof el.selectionStart == "number" &&
      typeof el.selectionEnd == "number"
    ) {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end,
    };
  };

  const addInspirationV2 = (e) => {
    // Create the new description with the added text
    const newText =
      description.slice(0, position.start) +
      e +
      description.slice(position.end);

    // Update the position and description state
    setPosition((prevPosition) => ({
      start: prevPosition.start + e.length,
      end: prevPosition.end + e.length,
    }));
    setDescription(newText);
  };

  const onEmojiSelect = (emoji, id, native, skin) => {
    // Update the state with the selected emoji data
    setEmojiData(emoji);
    setEmojiId(id);
    setEmojiNative(native);
    setEmojiSkin(skin);

    addInspirationV2(native);
  };

  const handleNext = () => {
    if (page === 1) setPage(2);
  };

  const handleBack = () => {
    if (page === 2) setPage(1);
  };

  const handlePost = () => {
    let recognitionPostDetails = {
      post_images: [],
      description: description,
      shoutout: { exemplifies: getUserId },
      exemplify_id: exemplifyId,
      title: "",
      template_id: null,
    };
    createPost(recognitionPostDetails);
    onClose();
  };

  const onChangeInput = (e) => {
    searchUserName(e.target.value);
    getTeamMemberDetails(e.target.value);
  };

  const addUser = (newUser) => {
    // Check if the newUser already exists in the array based on uid
    const userExists = getSearchUser.some((user) => user.uid === newUser.uid);

    if (!userExists) {
      // If the user does not exist, add the new user to the array
      getSearchUserData([...getSearchUser, newUser]);
      getUserIdData([...getUserId, newUser.uid]); // Correctly store the uid in array form
    }

    searchUserName("");
  };

  const removeUserByUid = (uid) => {
    const updatedUsers = getSearchUser.filter((user) => user.uid !== uid);
    getSearchUserData(updatedUsers);
    const updatedUserIds = updatedUsers.map((user) => user.uid);
    getUserIdData(updatedUserIds); 
  };

  const createPost = async (payload) => {
    const postData = {
      ...payload,
      title: "",
    };
    try {
      const res = await fetchApi(AddInspirationPost, "POST", postData);
      if (res.data.message) {
        toast.error(res.data.message);
      } else {
        toast.success(res.data[0]);
      }
    } catch (error) {
      // toast.error(error)
    }
  };

  return (
    <StyledModal show={show} onHide={onClose} centered width="800px">
      <ModalContent /*isHeight={recognitionType === "recognition" ?  "800px" : "565px"}*/
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Heading>
            {recognitionType === "recognition"
              ? "Give Recognition"
              : recognitionType === "birthday"
                ? "Celebrating a Birthday"
                : "Celebrating a Work Anniversary"}
          </Heading>
          <div style={{ cursor: "pointer" }} onClick={onClose}>
            {ManagerCloseIcon()}
          </div>
        </div>
        <Divider isMargin={"16px 0px"}></Divider>
        <ProfileContainer>
          <AvatarContainer isProfile={true}>
            <LazyImage
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "40px",
                border: "1px solid #DCDCDC",
              }}
              onError={() => window.console.error("Image failed to load")}
              src={`${ImageUrl}/${loggedInUser?.profile_image}`}
            />
          </AvatarContainer>
          <div>{`${loggedInUser?.fname} ${loggedInUser?.lname}`}</div>
        </ProfileContainer>

        {page === 1 && (
          <>
            {recognitionType === "birthday" ||
            recognitionType === "anniversary" ? (
                <>
                  <FieldTitle marginTop={"24px"} isFont={"Lato"}>
                    {"Select Template"}
                  </FieldTitle>
                  <TemplateContainer>
                    {templateData?.map((template, index) => (
                      <TemplateOption key={template.id}>
                        <LazyImage
                          style={{
                            width: "224px",
                            height: "224px",
                            borderRadius: "4px",
                            border: "1px solid #DCDCDC",
                          }}
                          onError={() =>
                            window.console.error("Image failed to load")
                          }
                          src={`${ImageUrl}/${template?.template_url}`}
                        />
                        <RadioButtonWrapper>
                          <RadioButton
                            type="radio"
                            name="template"
                            checked={selectedTemplate === template.id}
                            onChange={() => handleTemplateSelect(template.id)}
                          />
                          <Label>Option {index + 1}</Label>
                        </RadioButtonWrapper>
                      </TemplateOption>
                    ))}
                  </TemplateContainer>
                  <Divider isMargin={"16px 0px"}></Divider>
  
                  <Button onClick={handleNext} disabled={!selectedTemplate}>
                    Next{" "}
                    <div>
                      {!selectedTemplate
                        ? ManagerDisabledArrowIcon()
                        : ManagerArrowIcon()}
                    </div>
                  </Button>
  
                  {/* <Main style= {{display:"flex", justifyContent:"center"}}>
                    <CommonButton
                      btnType={"squareIcon"}
                      onClick={handleNext}
                      disabled={!selectedTemplate}
                      title={("Next")}
                      styles={{color:"#00ABB6",border:"white", fontFamily:"Lato"}}
                      nextIcon={ManagerArrowIcon()}
                    />
                  </Main> */}
                </>
              ) : (
                <>
                  <TitleContainer
                    isPadding={"0px"}
                    padding="0px !important"
                    heightV="240px"
                    margin="20px 0px 0px 0px"
                    manager
                  >
                    <FieldTitle isFont={"Lato"} manager color={"#184A61"}>
                      {"Add Recognition"}
                    </FieldTitle>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{
                        marginTop: "12px",
                        borderRadius: "4px",
                        padding: "14px 16px",
                        "  & .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: focused ? "#00ABB6" : "#BDBDBD",
                          },
                          "&:hover fieldset": {
                            borderColor: "#BDBDBD",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#00ABB6",
                          },
                        },
                      }}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                    >
                      <InputLabel
                        id="select-recognition-label"
                        sx={{
                          fontFamily: "Lato",
                          fontSize: "16px",
                          color: focused ? "#00ABB6" : "#BDBDBD",
                          "&.Mui-focused": {
                            color: "#00ABB6",
                          },
                        }}
                      >
                        Select recognition
                      </InputLabel>
                      <Select
                        labelId="select-recognition-label"
                        id="select-recognition"
                        // value={selectedValue}
                        // onChange={(e) => handleChange(exemplifiesData?.[e.target.selectedIndex]?.id, exemplifiesData?.[e.target.selectedIndex]?.core_value)}
                        value={exemplifiesData?.findIndex(
                          (item) => item.core_value === selectedValue || ""
                        )}
                        onChange={handleChange}
                        label="Select recognition"
                        sx={{
                          color: "#184A61",
                          width: "100%",
                          "& .MuiSelect-icon": {
                            color: "#00ABB6",
                            // fontSize: "16px",
                            // width: "16px",
                            // height: "16px",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 200,
                              width: 300,
                              "&::-webkit-scrollbar": {
                                width: "4px",
                                height: "100px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#BDBDBD",
                                borderRadius: "5.5px",
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "white",
                              },
                            },
                          },
                          MenuListProps: {
                            sx: {
                              paddingTop: 0,
                              paddingBottom: 0,
                            },
                          },
                        }}
                      >
                        {(Array.isArray(exemplifiesData)
                          ? exemplifiesData
                          : []
                        ).map((item, index) => (
                          <MenuItem
                            key={index}
                            value={index}
                            sx={{
                              color: "#184A61",
                              padding: "14px 16px",
                              "&:hover": { backgroundColor: "light blue" },
                            }}
                          >
                            {item.core_value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TitleContainer>
  
                  <Divider isMargin={"16px 0px"}></Divider>
                  <FieldTitle isFont={"Lato"} color={"#184A61"}>
                    {"Who would you like to recognize?"}
                  </FieldTitle>

                  <UserContainer
                    showSuggestor={searchUser}
                    isManagerMargin="12px"
                    width="350px"
                  >
                    {recipient.length > 0 &&
                    recipient?.map((user, index) => (
                      <UserDropdownContainer
                        key={index}
                        index={index}
                        // onMouseEnter={() => this.handleMouseHover(index)}
                        currentSelection={searchUser}
                        // onClick={addUser(index)}
                      >
                        <ProfilePicture>
                          <DropDownStyledImage width="100%" left height="30px">
                            <div className="middle">
                              <Image
                                image={user?.profile_image}
                                alt={user?.fname}
                              />
                            </div>
                            <ListDropdown
                              index={index}
                              currentSelection={searchUser}
                              onClick={() => addUser(user)}
                            >
                              {user?.fullname}
                            </ListDropdown>
                          </DropDownStyledImage>
                        </ProfilePicture>
                      </UserDropdownContainer>
                    ))}
                  </UserContainer>
                  <StyledInputContainer>
                    {getSearchUser.length > 0 &&
                    getSearchUser.map((data, index) => (
                      <ShowTaggedUser key={index}>
                        <img
                          src={`${ImageUrl}/${data?.profile_image}`}
                          style={{
                            borderRadius: "40px",
                            width: "28px",
                            height: "28px",
                            border: "1px solid #DCDCDC",
                          }}
                          alt="profile"
                        />
                        <div>{data?.fullname}</div>
                        <Cross
                          onClick={() =>
                            data?.uid && removeUserByUid(data?.uid)
                          }
                        >
                          {ManagerCrossIcon()}
                        </Cross>
                      </ShowTaggedUser>
                    ))}
                    <StyledInput
                      placeholder={getSearchUser?.length > 0 ? "" : "Enter names"}
                      name="title"
                      onChange={getSearchUser?.length > 9 ? null : onChangeInput}
                      value={searchUser}
                      maxLength="100"
                      cursor={getSearchUser?.length > 9}
                    />
                  </StyledInputContainer>
                  <NoteText>
                  (You can enter the names of up to 10 individuals)
                  </NoteText>
                  <Divider isMargin={"24px 0px 16px 0px"}></Divider>

                  <Button onClick={handleNext} disabled={!selectedValue}>
                  Next{" "}
                    <div>
                      {" "}
                      {!selectedValue
                        ? ManagerDisabledArrowIcon()
                        : ManagerArrowIcon()}
                    </div>
                  </Button>
                </>
              )}
          </>
        )}
        {page === 2 && (
          <>
            <LeftContent>
              <div>
                <textarea
                  placeholder={"Write here. You can also include @mentions."}
                  rows="3"
                  name=""
                  onChange={(e) => addDescription(e)}
                  value={description}
                  data-gramm_editor="false"
                  className="CustomTextAreaField"
                  // onScroll={onScroll}
                  spellCheck="false"
                  ref={textareaRef}
                  onInput={(e) => {
                    addDescription(e);
                  }}
                  onClick={(e) => addDescription(e)}
                />
              </div>
            </LeftContent>
            <Divider isMargin={"16px 0px"}></Divider>
            <div
              style={{
                width: "752px",
                height: "32px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div onClick={showEmojiPicker}>{ManagerEmojiIcon()}</div>
              <CharacterCount style={{ color: charColor }}>
                {description.length}/{maxChars} | C
              </CharacterCount>
            </div>
            <div style={{ margin: "auto", display: "flex", gap: "16px" }}>
              <Button
                isBg={true}
                onClick={handleBack}
                color="red"
                hoverColor="#5a6268"
              >
                <div>{ManagerLeftArrowIcon()}</div> Back
              </Button>
              <Button onClick={handlePost} disabled={!description}>
                Post{" "}
                <div>
                  {!description ? ManagerDisabledPostIcon() : ManagerPostIcon()}
                </div>
              </Button>
            </div>
            {displayEmoji ? (
              <SocialFeedsEmojiPicker
                selectEmoji={onEmojiSelect}
                emojiRef={emojiref}
                hidePicker={hideEmojiPicker}
              />
            ) : null}
          </>
        )}
      </ModalContent>
    </StyledModal>
  );
};

RecognitionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modalData: PropTypes.func.isRequired,
  recognitionType: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object.isRequired,
};

export default RecognitionModal;
